import { template as template_9cfa6eca694745eaadb4319566b4486c } from "@ember/template-compiler";
const WelcomeHeader = template_9cfa6eca694745eaadb4319566b4486c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
