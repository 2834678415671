import { template as template_07cccc562aca4b67b84102ac22a4dd0a } from "@ember/template-compiler";
const FKText = template_07cccc562aca4b67b84102ac22a4dd0a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
