import { template as template_6beb4fe973ac4d1c86a469bb3e5e8353 } from "@ember/template-compiler";
const FKLabel = template_6beb4fe973ac4d1c86a469bb3e5e8353(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
